<template>
  <section v-if="error">
    <PaginaError />
  </section>
  <section v-if="loading">
    <PaginaCarga />
  </section>
  <section v-else id="learndativity">
      <Learndativity :datos="json"/>
  </section>
</template>
<style scoped>

@media (min-width: 1023px) {
  html,body{
    overflow: hidden;
  }
}
</style>
<script>
import { useQuery } from "@vue/apollo-composable";
import LearndativityDatos from "../graphql/Learndativity.gql";
import { computed } from "vue";

import PaginaCarga from "@/components/paginaCarga/paginaCarga.vue";
import PaginaError from "@/components/paginaError/paginaError.vue";
import Learndativity from '@/components/learndativity/LearndativityPrincipal.vue'

export default {
  name: 'LearndativityView',
  components: {
    PaginaCarga,
    PaginaError,
    Learndativity
  },
  setup() {
    const { result, loading, error } = useQuery(LearndativityDatos.getLearndativity);
    const json = computed(() => result.value?.getLearndativity ?? []);

    return { json, loading, error };
  },
}
</script>